// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../images/pages/login_bg_img.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lodinlogo {
  border: 1px solid black;
  border-radius: 50%;
  width: 50%;
}

.inputlogo {
  width: 10%;
}

.input_div {
  background-color: #FBE8E7;
  padding: 10px;
  display: flex;
  gap: 5px;
  width: 100%;
  border-radius: 8px;
}

.input_box {
  border: none;
  outline: none;
  background-color: #FBE8E7;
}

.card_div {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5490196078);
  border: 1px solid #707070;
  border-radius: 40px;
  opacity: 1;
  padding: 5px 20px;
}

.loginbtn {
  background-color: #ED6D7D;
  color: white;
  border-radius: 12px;
  width: 60%;
  border: 0;
  padding: 5px;
}

.bgimage {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/dark/pages/auth/_authentication.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,kBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;AACD;;AAEA;EACC,yBAAA;EACA,aAAA;EACA,aAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;AACD;;AAGA;EACC,YAAA;EACA,aAAA;EACA,yBAAA;AAAD;;AAGA;EAEC,mDAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,iBAAA;AADD;;AAGA;EACC,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,SAAA;EACG,YAAA;AAAJ;;AAGA;EACC,iFAAA;EAIC,sBAAA;AAAF","sourcesContent":[".lodinlogo {\n\tborder: 1px solid black;\n\tborder-radius: 50%;\n\twidth: 50%;\n}\n\n.inputlogo {\n\twidth: 10%;\n}\n\n.input_div {\n\tbackground-color: #FBE8E7;\n\tpadding: 10px;\n\tdisplay: flex;\n\tgap: 5px;\n\twidth: 100%;\n\tborder-radius: 8px;\n\n}\n\n.input_box {\n\tborder: none;\n\toutline: none;\n\tbackground-color: #FBE8E7;\n}\n\n.card_div {\n\n\tbox-shadow: 3px 3px 6px #0000008C;\n\tborder: 1px solid #707070;\n\tborder-radius: 40px;\n\topacity: 1;\n\tpadding: 5px 20px;\n}\n.loginbtn{\n\tbackground-color: #ED6D7D;\n\tcolor: white;\n\tborder-radius: 12px;\n\twidth: 60%;\n\tborder: 0;\n    padding: 5px;\n}\n\n.bgimage{\n\tbackground: url(../../../../images/pages/login_bg_img.svg) no-repeat center center fixed; \n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
