import React, { useState, useEffect, useContext, memo, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import order from "../../../../assets/images/icons/order.svg";
import customer from "../../../../assets/images/icons/customer.svg";
import advertise from "../../../../assets/images/icons/advertist.svg";
import track from "../../../../assets/images/icons/track.svg";
import revenue from "../../../../assets/images/icons/revenue.svg";
import product from "../../../../assets/images/icons/product.svg";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo((props) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  //location
  useEffect(() => {
    init();
  }, []);
  function init() {
    try {
      loginCheck();
    } catch (error) {
      console.log("error : ", error);
    }
  }
  function loginCheck() {
    try {
      let user_data = JSON.parse(sessionStorage.getItem("admin_detatils"));
      if (!user_data) {
        navigate("/");
      }
    } catch (error) {
      console.log("error : ", error);
    }
  }
  let location = useLocation();
  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li
          className={`${
            location.pathname === "/dashboard" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/dashboard" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/dashboard"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={order} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">DashBoard</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/order" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/order" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/order"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={order} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Order</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/product" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/product" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/product"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={product} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Product</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/customer" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/customer" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/customer"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={customer} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Customers</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/advertisement" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/advertisement" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/advertisement"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={advertise} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Advertisement</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/invoice" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/invoice" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/invoice"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={track} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Invoice</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/trackOrder" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/trackOrder" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/trackOrder"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={track} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Track Order</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/ratting" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/ratting" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/ratting"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={track} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Ratting</span>
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "/revenue" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/revenue" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/revenue"
            onClick={() => {}}
          >
            <i className="icon">
              <img src={revenue} style={{ width: "20px" }}></img>
            </i>
            <span className="item-name">Revenue</span>
          </Link>
        </li>
        <Accordion.Item
          as="li"
          eventKey="horizontal-menu"
          bsPrefix={`nav-item ${active === "master" ? "active" : ""} `}
          onClick={() => setActive("master")}
        >
          <CustomToggle
            eventKey="horizontal-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Master</span>
            <i className="right-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle> 
          <Accordion.Collapse eventKey="horizontal-menu">
            <ul className="sub-nav">
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/category" ? "active" : ""
                  } nav-link`}
                  to="/master/category"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> C </i>
                  <span className="item-name"> Category </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/subCategory" ? "active" : ""
                  } nav-link`}
                  to="/master/subCategory"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> C </i>
                  <span className="item-name"> SubCategory </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/productType" ? "active" : ""
                  } nav-link`}
                  to="/master/productType"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Product Type</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/fabricType" ? "active" : ""
                  } nav-link`}
                  to="/master/fabricType"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Fabric Type</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/sleevePattern"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/master/sleevePattern"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Sleeve Pattern</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/neckDesign" ? "active" : ""
                  } nav-link`}
                  to="/master/neckDesign"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Neck Design</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/occasion" ? "active" : ""
                  } nav-link`}
                  to="/master/occasion"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Occasion</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/productSize" ? "active" : ""
                  } nav-link`}
                  to="/master/productSize"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Product Size</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/brand" ? "active" : ""
                  } nav-link`}
                  to="/master/brand"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Brand</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/postService" ? "active" : ""
                  } nav-link`}
                  to="/master/postService"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Postal Service</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/tax" ? "active" : ""
                  } nav-link`}
                  to="/master/tax"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Tax</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/master/deliveryCharge"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/master/deliveryCharge"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Delivery Charge</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
});

export default VerticalNav;
